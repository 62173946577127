<template>
  <list :type="0"/>
</template>
<script>
import list from './Index.vue'

export default {
  name: 'Index',
  components: {
    list,

  },
}
</script>
<style scoped lang="less">

</style>
